/**
 * Copyright 2024 Vitaliy Zarubin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html, body, .App {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.3;
    overflow: hidden;
}

#root.ScrollSection {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

a {
    outline: none;
    text-decoration: none;
    color: #40287F;
}

a:hover {
    text-decoration: underline;
}

.ThemeDark a {
    color: #CB8FF7;
}

// Table
.Table {
    display: table;
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

.Table-Row {
    display: table-row;
}

.Table-Cell {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}

/* Dark apexcharts */
.ThemeDark .apexcharts-text {
    fill: #fff;
}
.ThemeDark .apexcharts-tooltip,
.ThemeDark .apexcharts-tooltip-title {
    background-color: #333 !important;
    color: #fff !important;
    border: 1px solid #555 !important;
  }
.ThemeDark .apexcharts-legend-text {
    color: #fff !important;
    border: 1px solid #555 !important;
  }

// @todo Bug MUI https://github.com/mui/base-ui/issues/167
.UserPage textarea {
    overflow-y: scroll !important;
}
